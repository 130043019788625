/* font face */
@font-face {
  font-family: Ariomex;
  src: url(./assets/fonts/ariomex.woff2);
}
@font-face {
  font-family: Vazirmatn;
  src: url(./assets/fonts/Vazirmatn-RD-FD-Regular.woff2);
}
@font-face {
  font-family: VazirmatnMedium;
  src: url(./assets/fonts/Vazirmatn-RD-FD-Medium.woff2);
}
@font-face {
  font-family: VazirmatnBold;
  src: url(./assets/fonts/Vazirmatn-RD-FD-Bold.woff2);
}
@font-face {
  font-family: VazirmatnLight;
  src: url(./assets/fonts/Vazirmatn-RD-FD-Light.woff2);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100% !important;
}
